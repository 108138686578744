/**************************** Common ****************************/
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/**************************** Navbar ****************************/
.nav-link:hover {
  text-decoration: underline;
}

.menu-items {
  font-family: "Verdana", sans-serif;
}

.menu-items:hover {
  text-decoration: underline;
  text-underline-offset: 5px;
}

/**************************** Footer ****************************/
@media screen and (max-width: 600px) {
  footer {
    font-size: 14.5px;
  }
}

/**************************** Home ****************************/
.home-input-fields {
  background-color: white;
  border: solid 1px white;
  color: black;
  font-size: 16px;
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 400px;
}

.caption {
  position: absolute;
  top: 15%;
  left: 0;
  width: 100%;
  color: white;
  /* text-shadow: 1.25px 1.25px black; */
}

@media screen and (max-width: 600px) {
  .home-input-fields {
    width: 275px;
    font-size: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 14px;
  }
  .caption {
    top: 7.5%;
  }
}

/**************************** About ****************************/
.about-image {
  width: 650px;
}

@media screen and (max-width: 600px) {
  .about-image {
    width: 300px;
  }
}

/**************************** Contact ****************************/
.contact-input-fields {
  background-color: transparent;
  border: solid 1px white;
  color: white;
  font-size: 16px;
  padding-left: 10px;
  padding-top: 14px;
  padding-bottom: 14px;
  width: 450px;
}

@media screen and (max-width: 600px) {
  .contact-input-fields {
    width: 275px;
    font-size: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 14px;
  }
}

/**************************** Blogs ****************************/
.search-bar {
  background-color: transparent;
  width: 325px;
  border: solid 1px white;
  border-radius: 5px;
}

@media screen and (max-width: 600px) {
  .search-bar {
    width: 275px;
  }
}

/**************************** Loading ****************************/
.lds-roller {
  display: inline-block;
  position: relative;
  width: 100px;
  height: 100px;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #00bdfe;
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
